.grid_row--scale {
  background-color: #dff9d6;
}
.grid_row--optimized {
  background-color: #e1f1fe;
}
.grid_row--maintain {
  background-color: #fff5d9;
}
.grid_row--test {
  background-color: #e6dff680;
}

.grid_row--abo {
  background-color: #dff5f9;
}

.grid_row--scale--max,
.grid_row--optimized--max,
.grid_row--test--max,
.grid_row--maintain--max,
.grid_row--abo--max {
  background-color: #a3da8a;
}

.grid_row--scale--medium,
.grid_row--optimized--medium,
.grid_row--maintain--medium,
.grid_row--test--medium,
.grid_row--abo--medium {
  background-color: #d4edbc;
}

.grid_row--maintain--hold,
.grid_row--scale--hold,
.grid_row--optimized--hold,
.grid_row--test--hold,
.grid_row--abo--hold {
  background-color: #ffeeb9;
}

.grid_row--scale--off,
.grid_row--optimized--off,
.grid_row--maintain--off,
.grid_row--test--off,
.grid_row--abo--off {
  background-color: #fccfc9;
}
.cpc--warning {
  background-color: hsl(7, 100%, 95%);
}
.custom_toolbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.header-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid_row--scale--warning,
.grid_row--optimized--warning {
  background-color: #fccfc9;
}
.grid_row--scale--shut_down {
  background-color: #f38a7d;
}

.grid_row--good-ar {
  background-color: #dff9d6;
}
.grid_row--bad-ar {
  background-color: #fccfc9;
}
.grid_row--warning-ar {
  background-color: #ffeeb9;
}

.grid_row--good-roas {
  background-color: #dff9d6;
}
.grid_row--bad-roas {
  background-color: #fccfc9;
}
.grid_row--warning-roas {
  background-color: #ffeeb9;
}
